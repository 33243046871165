import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { BusinessType, ConterParty } from '../../../../@types/conterParty';
import { EntityDeals } from '../../../../@types/deals/deals';
import { LegalEntity } from '../../../../@types/legal-entities';
import { EntityParticipation } from '../../../../@types/participation/participation';
import { People } from '../../../../@types/people';
import {
  InputFormsNotes,
  NoteTypes,
} from '../../../../@types/segregatedaccounts/notes';
import { useAppDispatch } from '../../../../hooks/redux';
import { useLazyGetCounterPartyByIdQuery } from '../../../../redux/api/ws/counterparties/get';
import { useFilterDealsByNotesMutation } from '../../../../redux/api/ws/deals/deals';
import { useGetLegalEntityByConterPartIdMutation } from '../../../../redux/api/ws/enquiries/enquiries';
import { useSearchCofarcoMutation } from '../../../../redux/api/ws/mandates/mandates';
import { useGetParticipationByDealIdMutation } from '../../../../redux/api/ws/participations/participations';
import {
  useCreateNoteMutation,
  useGetPeopleInNoteMutation,
} from '../../../../redux/api/ws/segregatedaccounts/notes';
import {
  useLazyGetBanksAccountQuery,
  usePostAddIbanMutation,
} from '../../../../redux/api/ws/segregatedaccounts/payement';
import { updateNotificationMessage } from '../../../../redux/slice/notificationSlice';
import {
  exportDateUi,
  exportDateWs,
  exportUniqueId,
  floorNumber,
  isExist,
  nullingEmptyValues,
  setDefaultValues,
} from '../../../../utils/helper-function';
import { updateModalAction } from '../../../../redux/slice/modalSlice';
import moment from 'moment';
import { InputFormBankAccount } from '../../../../@types/segregatedaccounts/payements';

type IProps = {
  defaultFormValues: any | null;
  closingModal: (itemsDataSource: any | null) => void;
};

export default function UseModalNotes(props: IProps) {
  const [getDeals, { isLoading: isLoadingDeals }] =
    useFilterDealsByNotesMutation();
  const [dealList, setDealList] = useState<Array<EntityDeals>>([]);
  const [participationList, setParticipationList] = useState<
    Array<EntityParticipation>
  >([]);
  const [isCompletedForm, setIsCompletedForm] = useState(false);

  const [getCofarco, { isLoading: loadingBroker }] = useSearchCofarcoMutation();
  const [getLegalEntity] = useGetLegalEntityByConterPartIdMutation();
  const [getCounterPartyById] = useLazyGetCounterPartyByIdQuery();
  const [getParticipationByDealId] = useGetParticipationByDealIdMutation();
  const [getAttPeople] = useGetPeopleInNoteMutation();
  const [createNotes, { isLoading: isCreatingNote }] = useCreateNoteMutation();
  const dispatch = useAppDispatch();
  const [getBankAccount] = useLazyGetBanksAccountQuery();
  const [ibanList, setIbanList] = useState<Array<any>>([]);
  const [postAddIban] = usePostAddIbanMutation();

  const [brokerList, setBrokerList] = useState<Array<ConterParty>>([]);
  const [receipientList, setReceipientList] = useState<Array<LegalEntity>>([]);
  const [selectedCounterParty, setSelectedCounterParty] =
    useState<ConterParty | null>(null);

  const [attPeople, setAttPeople] = useState<Array<People>>([]);

  const [tempCounterPartyId, setTempCounterPartyId] = useState<number | null>(
    null,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
    reset,
    setError: setFormError,
    clearErrors: clearFormErrors,
  } = useForm<InputFormsNotes>({
    mode: 'onBlur',
    defaultValues: {
      issuing_date: exportDateUi(new Date()) as string,
      amount: '000',
      tax_amount: '0.00',
      gross_rate: '0.000000 %',
      net_rate: '0.000000 %',
      commission_rate: '% 0.000',
    },
  });

  const note_type = watch('note_type');
  const counterparty_id = watch('counterparty_id');
  const attention_contact_id = watch('attention_contact_id');
  const broker_role = watch('broker_role');
  const legal_entity_id = watch('legal_entity_id');
  const premium_calculation_basis = watch('premium_calculation_basis');
  const deal_id = watch('deal_id');
  const participation_id = watch('participation_id');
  const premium_type = watch('premium_type');
  const currency = watch('currency');
  const entity_bank_account_id = watch('entity_bank_account_id');
  const isRequiredSettledDate =
    note_type === 'ccni' || note_type === 'pdni' ? true : false;

  const showColNoteTypes = ['ccnb', 'cdnb', 'pcni', 'pcnu', 'pdni', 'pdnu'];
  const showCol1NoteTypes = ['cdnu', 'ccni'];
  const subClassIbanTypes = ['ccni', 'pcni', 'pcnu'];

  const subClassIbanReceipt =
    subClassIbanTypes.includes(note_type as string) && isExist(legal_entity_id)
      ? ''
      : 'd-none';
  const isShowCol =
    isExist(premium_type) && showColNoteTypes.includes(note_type);
  const isShowCol1 =
    isExist(legal_entity_id) && showCol1NoteTypes.includes(note_type);

  const colClass = isShowCol || isShowCol1 ? '' : 'd-none';

  const subClassRateTypes = ['pcni', 'ccni', 'pdni', 'cdnu', 'pcnu', 'pdnu'];
  const subClassRate = subClassRateTypes.includes(note_type as string)
    ? 'd-none'
    : '';

  const isHiddeEndorSementnumber = ['cdnu', 'ccni'].includes(
    note_type as string,
  )
    ? 'd-none'
    : '';

  const labelAmout = ['cdnu', 'ccni'].includes(note_type as string)
    ? 'Claim Amount'
    : 'Premium Amount';
  const isUnderWriterReference = ['cdnu', 'pcnu', 'pdnu'].includes(
    note_type as string,
  );
  const isClientReference = ['pcni', 'pdni', 'ccni'].includes(
    note_type as string,
  );

  const labelReference = isClientReference
    ? 'Client Reference'
    : isUnderWriterReference
      ? 'Underwriter \nReference'
      : 'Partner Reference';

  const labelCalculatrice =
    note_type === 'cdnu' || note_type === 'ccni'
      ? 'Claim Calculation \nBasis'
      : 'Premium Calculation \nBasis';

  const isShowIconeRecipient = subClassRateTypes.includes(note_type as string);

  const isShowIconeParticipation = ['cdnu', 'pcnu', 'pdnu'].includes(
    note_type as string,
  );

  const [showModalAddIban, setShowModalAddIban] = useState(false);

  const removeStateFilter = () => {
    setDealList([]);
    setBrokerList([]);
    setReceipientList([]);
    setSelectedCounterParty(null);
    setAttPeople([]);
    setParticipationList([]);
  };

  const resetForm = () => {
    const defaultValue = setDefaultValues<InputFormsNotes>(watch());
    reset({
      ...defaultValue,
      deal_id: undefined,
      issuing_date: exportDateUi(new Date()) as string,
      amount: '000',
      tax_amount: '0.00',
      gross_rate: '0.000000 %',
      net_rate: '0.000000%',
      commission_rate: '% 0.000',
    });
    removeStateFilter();
    setTempCounterPartyId(null);
  };

  const handleGetDeals = async (text: string) => {
    try {
      const response = await getDeals({
        query: `%${text?.trim()}%`,
      }).unwrap();
      setDealList(response?.deal || []);
      return response;
    } catch (error) {
      return [];
    }
  };

  const handleGetAttPeople = async (
    counterpartyId: number,
    dealId: number,
    noteType: string,
  ) => {
    try {
      const response = await getAttPeople({
        counterpartyId,
        dealId,
        noteType,
      }).unwrap();
      setAttPeople(exportUniqueId(response?.people || []));
      return response;
    } catch (error) {
      return [];
    }
  };

  const handleGetCounterPartyById = async (id: number) => {
    try {
      const response = await getCounterPartyById({
        id: id,
      }).unwrap();
      setSelectedCounterParty(response?.counterparty);
      return response;
    } catch (error) {
      return [];
    }
  };

  const handleParticipationById = async (dealId: number) => {
    try {
      const response = await getParticipationByDealId({
        dealId: dealId,
      }).unwrap();
      setParticipationList(response?.participation);
      return response;
    } catch (error) {
      return [];
    }
  };

  const handlCounterPartie = async (
    text?: string,
    type?: BusinessType,
    id?: number,
  ) => {
    try {
      const params = {
        page: 1,
        start: 0,
        limit: 100,
        query: text ? `%${text?.trim()}%` : undefined,
        businessType: type || 'client',
      };

      const response = await getCofarco(
        id
          ? {
              ...params,
              filter: JSON.stringify([
                { property: 'id', operator: '=', value: id },
              ]),
            }
          : { ...params },
      ).unwrap();
      const tempOption = response?.counterparty;

      if (type === 'partner') {
        setBrokerList(tempOption);
      }
    } catch (error) {
      return [];
    }
  };

  const handleLegalEntityById = async (id?: number) => {
    try {
      const response = await getLegalEntity({ id: id as number }).unwrap();
      const tempOption = response?.legalentity;
      if (tempOption) {
        setReceipientList(exportUniqueId(tempOption || []));
      }
    } catch (error) {
      return [];
    }
  };

  const hanleBank = async (legalEntityId?: number | null) => {
    try {
      const resp = await getBankAccount({
        page: 1,
        start: 0,
        limit: 25,
        legalEntityId: legalEntityId as number,
      }).unwrap();
      const makeB = resp?.bankaccount?.map((el) => ({
        ...el,
        key: el.id,
        value: `${el.bank} - ${el.currency?.toUpperCase()} - ${el.iban} - ${
          el.info
        }`,
      }));
      setIbanList(makeB);
    } catch (error) {
      console.log(error);
    }
  };

  const addIban = async (value: InputFormBankAccount) => {
    try {
      value.legal_entity_id = legal_entity_id;
      await postAddIban(value)?.unwrap();
      hanleBank(watch('legal_entity_id'));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      const isBroker = value.note_type === 'ccnb' || value.note_type === 'cdnb';

      if (name === 'deal_id') {
        // setValue('legal_entity_id', undefined);
        const defaultValue = setDefaultValues<InputFormsNotes>(watch());
        reset({
          ...defaultValue,
          issuing_date: exportDateUi(new Date()) as string,
          amount: '0.00',
          tax_amount: '0.00',
          gross_rate: '0.000000 %',
          net_rate: '0.000000 %',
          commission_rate: '% 0.000',
          deal_id: value.deal_id,
        });
      }
      if (name === 'note_type') {
        const defaultValue = setDefaultValues<InputFormsNotes>(watch());
        reset({
          ...defaultValue,
          issuing_date: exportDateUi(new Date()) as string,
          amount: '0.00',
          tax_amount: '0.00',
          gross_rate: '0.000000 %',
          net_rate: '0.000000 %',
          commission_rate: '% 0.000',
          deal_id: value.deal_id,
          note_type: value.note_type,
        });
      }

      const isInsured =
        value.note_type === 'ccni' ||
        value.note_type === 'pcni' ||
        value.note_type === 'pdni';

      const isUnderWriter =
        value.note_type === 'cdnu' ||
        value.note_type === 'pcnu' ||
        value.note_type === 'pdnu';

      const isRequiredParams =
        name === 'note_type' ||
        name === 'deal_id' ||
        name === 'counterparty_id' ||
        name === 'participation_id';

      if (isRequiredParams) {
        const selectedDeal = dealList?.find((el) => el.id === value.deal_id);
        let counterPartyId = 0;
        if (isBroker) {
          counterPartyId = value.counterparty_id as number;
        }
        if (isInsured) {
          counterPartyId = selectedDeal?.client_id as number;
        }

        if (isUnderWriter) {
          const selectedP = participationList?.find(
            (el) => el.id === value.participation_id,
          );
          counterPartyId = selectedP?.counterparty_id as number;
        }
        const isCompletedToGetAttContact =
          isExist(value.note_type) &&
          isExist(value.deal_id) &&
          isExist(counterPartyId);

        if (isCompletedToGetAttContact) {
          setTempCounterPartyId(counterPartyId);
          handleGetAttPeople(
            counterPartyId as number,
            value.deal_id as number,
            isBroker || isUnderWriter ? (value.note_type as string) : '',
          );
        }
      }

      if (name === 'deal_id') {
        if (!isExist(value.deal_id)) {
          reset();
          setSelectedCounterParty(null);
          setReceipientList([]);
          setSelectedCounterParty(null);
        }
      }
      if (name === 'legal_entity_id') {
        const isAcceptFetching =
          value.note_type === 'ccni' || 'pcni' || value.note_type === 'pcnu';
        if (isExist(value.legal_entity_id) && isAcceptFetching) {
          hanleBank(value.legal_entity_id);
        }
      }

      if (name === 'note_type' || name === 'deal_id') {
        const isFetchCounterParty =
          value.note_type === 'ccni' ||
          value.note_type === 'pcni' ||
          value.note_type === 'pdni' ||
          value.note_type === 'cdnu';

        const isFetchParticipation =
          value.note_type === 'cdnu' ||
          value.note_type === 'pcnu' ||
          value.note_type === 'pdnu';

        if (isFetchCounterParty) {
          const selectedDeal = dealList?.find((el) => el.id === value.deal_id);
          if (selectedDeal && selectedDeal?.client_id) {
            handleGetCounterPartyById(selectedDeal?.client_id as number);
          }
          //type Insured
          if (selectedDeal?.client_id && value.note_type !== 'cdnu') {
            const id = selectedDeal?.client_id;
            handleLegalEntityById(id);
          }
        }
        if (isFetchParticipation) {
          if (value.deal_id) {
            handleParticipationById(value.deal_id);
          }
        }
      }
      if (name === 'counterparty_id') {
        if (value?.counterparty_id) {
          const selectedB = brokerList?.find(
            (el) => el.id === value.counterparty_id,
          );
          //type broker
          if (selectedB && selectedB?.legal_entity_id) {
            handleLegalEntityById(selectedB?.id);
            setValue('legal_entity_id', selectedB?.legal_entity_id);
          }
        }
      }

      const isFetchRecipientInUnder =
        value.note_type === 'cdnu' ||
        value.note_type === 'pcnu' ||
        value.note_type === 'pdnu';

      if (name === 'participation_id' && isFetchRecipientInUnder) {
        if (value?.participation_id) {
          const selectedParticipation = participationList?.find(
            (el) => el.id === value.participation_id,
          );
          //type Underwriter
          if (selectedParticipation && selectedParticipation?.counterparty_id) {
            handleLegalEntityById(selectedParticipation?.counterparty_id);
          }
        }
      }
      if (name === 'settlement_date') {
        const dateDiffNow = moment(value.settlement_date).subtract(9, 'day');

        const dateDiff = dateDiffNow.diff(moment(new Date()), 'day');
        if (dateDiff >= 0) {
          setValue(
            'payment_date',
            exportDateUi(dateDiffNow.toDate()) as string,
          );
        } else {
          setValue('payment_date', exportDateUi(new Date()) as string);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, brokerList, dealList, participationList]);

  const runSearch = React.useCallback(
    async (key: keyof InputFormsNotes, value: string) => {
      if (value !== undefined && value !== null) {
        if (key === 'deal_id') {
          await handleGetDeals(value);
        }

        if (key === 'counterparty_id') {
          await handlCounterPartie(value, 'partner');
        }
      }
    },
    [],
  );

  const submit = async (data: InputFormsNotes) => {
    const params = {
      ...data,
      status: 'draft',
      issuing_date: exportDateWs(data.issuing_date),
      payment_date: exportDateWs(data.payment_date),
      settlement_date: exportDateWs(data.settlement_date),
      attention_contact_id: data?.attention_contact_id || null,
      counterparty_id: data?.counterparty_id || tempCounterPartyId,
      entity_bank_account_id: data?.entity_bank_account_id || null,
      legal_entity_id: data?.legal_entity_id || null,
      note_id: data?.note_id || null,
      participation_id: data?.participation_id || null,
      amount: floorNumber(data.amount),
      tax_amount: floorNumber(data.tax_amount),
      gross_rate: floorNumber(data.gross_rate),
      net_rate: floorNumber(data.net_rate),
      commission_rate: floorNumber(data.commission_rate),
    };

    try {
      const noteType = data.note_type as NoteTypes;
      const ccniPcni = [NoteTypes.ccni, NoteTypes.pcni].includes(noteType);
      /**lors de la création
       * d'une PCNU si  le "Recipient" est une Legal Entity dont
       * le Role est "Lloyd's Syndicate"
       * dans son rattachement à un Underwriter ID,
       * l'IBAN n'est pas obligatoire dans ces cas là. */
      const isLoydPcnu =
        !receipientList?.find((rec) => rec.id === data.legal_entity_id)
          ?.is_lloyds && data.note_type === NoteTypes.pcnu;

      const isRequiredIban = ccniPcni || isLoydPcnu;
      if (isRequiredIban && !isExist(entity_bank_account_id)) {
        dispatch(
          updateNotificationMessage({
            show: true,
            title: 'Error',
            body: "Recipient's IBAN missing",
          }),
        );
        return;
      }
      const response = await createNotes(nullingEmptyValues(params)).unwrap();
      if (response && response.success) {
        dispatch(
          updateNotificationMessage({
            title: 'Add Notes',
            body: `Notes ${response.note?.number} has been added`,
            show: true,
          }),
        );
        props.closingModal(response.note);
      }
    } catch (error) {
      props.closingModal(null);
    }
    resetForm();
  };

  React.useEffect(() => {
    const subscription = watch((value) => {
      let isCompleted: boolean = false;
      const required =
        isExist(value.note_type) &&
        isExist(value.premium_calculation_basis) &&
        isExist(value.currency) &&
        isExist(value.legal_entity_id) &&
        isExist(value.issuing_date)
          ? true
          : false;
      if (value.note_type === 'pdni') {
        isCompleted =
          required &&
          !!isExist(value.settlement_date) &&
          !!isExist(value.premium_type);
        setIsCompletedForm(isCompleted);
        return;
      }
      if (value.note_type === 'ccni') {
        isCompleted = required && !!isExist(value.settlement_date);
        setIsCompletedForm(isCompleted);
        return;
      }
      if (value.note_type === 'cdnu') {
        isCompleted = required;
        setIsCompletedForm(isCompleted);
        return;
      } else {
        isCompleted = required && !!isExist(value.premium_type);
        setIsCompletedForm(isCompleted);
        return;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const showPartner = (id: number | null | undefined) => {
    const found = (brokerList || []).find((el) => el.id === id);
    if (found) {
      dispatch(
        updateModalAction({
          data: found,
          isAdd: true,
          type: 'partner',
        }),
      );
    }
  };

  const showLegalEntity = (idLgl: number | null | undefined) => {
    const found = (receipientList || []).find(
      (el) => el.id?.toString() === idLgl?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
            group_id: found?.group?.id,
            group_name: found?.group?.name,
          },
          isAdd: true,
          type: 'legal_entity',
        }),
      );
    }
  };

  const showParticipation = (idPart: number | null) => {
    const found = (participationList || []).find(
      (el) => el.id?.toString() === idPart?.toString(),
    );
    if (found) {
      dispatch(
        updateModalAction({
          data: {
            ...found,
          },
          isAdd: true,
          type: 'participations',
        }),
      );
    }
  };

  const handleTitle = (notetype: string): string => {
    let title = '';
    switch (notetype) {
      case 'ccnb':
      case 'cdnb':
        title = 'New Commission Note';
        break;
      case 'ccni':
      case 'cdnu':
        title = 'New Claim Note';
        break;
      case 'pcni':
      case 'pdni':
      case 'pcnu':
      case 'pdnu':
        title = 'New Premium Note';
        break;
      default:
        title = 'New - Note';
        break;
    }
    return title;
  };

  return {
    resetForm,
    control,
    errors,
    register,
    runSearch,
    dealList,
    setValue,
    watch,
    setFormError,
    getValues,
    brokerList,
    note_type,
    receipientList,
    broker_role,
    legal_entity_id,
    selectedCounterParty,
    premium_calculation_basis,
    deal_id,
    participationList,
    participation_id,
    premium_type,
    currency,
    attPeople,
    attention_contact_id,
    counterparty_id,
    handleSubmit,
    submit,
    clearFormErrors,
    isCompletedForm,
    ibanList,
    entity_bank_account_id,
    isRequiredSettledDate,
    showPartner,
    showLegalEntity,
    showParticipation,
    handleGetDeals,
    handlCounterPartie,
    loadingBroker,
    isLoadingDeals,
    colClass,
    subClassIbanReceipt,
    subClassRate,
    isHiddeEndorSementnumber,
    labelAmout,
    labelReference,
    labelCalculatrice,
    isUnderWriterReference,
    handleTitle,
    isShowIconeRecipient,
    isShowIconeParticipation,
    showModalAddIban,
    setShowModalAddIban,
    isCreatingNote,
    addIban,
  };
}
